import { boot } from 'quasar/wrappers'
import Amplify, * as AmplifyModules from 'aws-amplify';

Amplify.configure({
  "aws_appsync_graphqlEndpoint": process.env.LANDING_GRAPHQL_ENDPOINT,
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": process.env.LANDING_GRAPHQL_APIKEY
})

export default boot(({ app }) => {
  app.use(AmplifyModules);
  app.config.globalProperties.$Amplify = Amplify;
})
