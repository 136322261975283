export function setCurrentTickerInfo(state, newCurrentTickerInfo) {
  state.currentTickerInfo = newCurrentTickerInfo;
}

export function setCurrentTickerStrategy(state, newDetailInfo) {
  state.currentTickerStrategy = newDetailInfo;
}

export function setLanguage(state, newLanguage) {
  state.language = newLanguage;
}

export function setMarketscanData(state, newData) {
  state.marketScanData = newData;
}
