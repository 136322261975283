// This is just an example,
// so you can safely delete all default props below

export default {
  Nav: {
    About: 'About',
    Products: 'Products',
    Pricing: 'Pricing',
    SkyBlueEducationBtn: 'Skyblue Education',
    LoginSignUpBtn: 'Login / Sign Up',
    MarketNews: 'Market News',
  },
  Hero: {
    Title: 'A skyful of tools to visualize the market from above.',
    Subtitle:
      'Invest with the highest US regulations and the top analytical tools with Artificial intelligence and Machine Learning algorithms applied to stocks, ETFs, and options.',
    PartnersInfo: 'We are now partners with',
  },
  InvestSection: {
    Title: 'Invest with us',
    Subtitle:
      'Skyblue Analytics offers a trading platform that supports investment in Stocks, ETFs, Options contracts, Bonds, Futures, and Crypto. Use our tools to discover and analyze the investment strategy that best suits you and put it to the test on the market either through our paper trading service or live through our partnered brokers.',
  },
  ProductsSection: {
    HideProduct: 'Hide Product',
    ViewFiltersUsed: 'View Filters Used',
    AppliedFilters: 'Applied Filters',
    SelectedStrategy: 'Strategy',
    LiveDemo: 'Live Demo',
    LastUpdated: 'Last updated',
    UpdatedAgo:  'ago',
    Title: 'Features and products',
    Subtitle: 'Our toolkit, designed with game-changing products, will make your trading more manageable, faster and simpler.',
    Columns:{
      StrategyName:'Strategy Name',
      ProbabilityOfProfit:'PoP',
      BreakEven1:'Break Even 1',
      BreakEven2:'Break Even 2',
      ReturnRisk:'Return/Risk',
      MaxLoss:'Max Loss',
      MaxReturn:'Max Return',
      StrategyIV:'Strategy IV',
      Ticker:'Ticker',
      IVRanking52:'IV Ranking 52W',
      IV:'IV',
      MarketCap:'Market Cap',
      CurrentPrice: 'Current  Price (Change)'
    },
    StrategyDetails: {
      Leg:'Leg',
      Strike:'Strike',
      BidAsk:'Price Bid/Ask (Mid)',
      StrategyIV:'Strategy IV',
      Delta:'Delta',
      Size:'Size',
    },
    Card: {
      Title: 'Real-time Optimization for Options Strategies',
      Info: 'We provide fast analysis of real-time data and customizable strategy recommendations for over 2000 stocks and over 300 ETFs for the US market.',
      Info2:
        'The customizability of strategies comes from the implementation of scan settings and filters such as expiration date, incoming earnings report filter, volatility and liquidity filters, and either manual or Skyblue-optimized strategy legs moneyness so the user can find just what they need.',
      MarketClosed: 'Market closed',
      MarketScan: 'Market Scan',
      InfoFiltersBtn: 'Views Filters Used',
    },
    Card2: {
      Title: 'Real-time Optimization for a chosen Ticker',
      Subtitle:
        'You may be pessimistic or optimistic about a company, or maybe you are looking at a company with high price volatility and are not sure about what strategy to use.',
      Info: 'With this tool, you will find the strategy that best fits a single ticker acording your criteria.',
      ViewProductBtn: 'View Product',
      TickerScan: 'Ticker Scan',
    },
    OtherProducts: {
      Title: 'Other products:',
      Card1: {
        Title: 'Probability of profit Portfolio Tracker',
        Subtitle:
          'We believe it is essential to <strong>keep a record of our trades</strong>, which is why we offer you the possibility of tracking strategies using our Market Scan and Ticket Scan. You can save these strategies to your portfolio and access real-time data about how the strategy performs from a <strong>greeks</strong> and <strong>value</strong> perspective. You can create as many <strong>portfolios</strong> as you need to track the evolution of relevant <strong>assets and contracts.</strong> Moreover, you will have access to a daily chart with <strong>changes in Probability of Profit</strong> for each strategy you track.',
      },
      Card2: {
        Title: 'Fundamental Explorer',
        Subtitle:
          'Using a company’s fundamental data for decision-making is quite challenging, even for professionals. And it’s even tougher when using it to compare companies! Our goal is to walk you through this vast amount of information in a user-friendly way so that you can make your decisions based on clear present and past data.',
      },
    },
    Mobile: {
      Products: 'Products',
      Text: 'Our <strong>toolkit</strong>, designed with game changing products, will make your trading <strong>simpler</strong> <strong>faster</strong>, and most importantly, <strong>more profitable</strong>.'},
    Newsletter: {
      Title: 'Subscribe to our newsletter',
      Subtitle: 'Every week you will receive useful stock and options analytics to keep track of the market.',
      InputEmail: 'Enter your email',
      SubscribeBtn: 'Subscribe',
    },
    BeforeTakeoff: {
      TopSubtitle: 'THE FLIGHT PLAN',
      Title: 'Before takeoff',
      TitleDoesItWork: 'How does it work?',
      SubtitleDoesItWork:
        'We receive and process millions of prices per second. This level of access to data, combined with the diligent work of our in-house experts and mathematicians, will provide you with exclusive real-time information for your trading.',
      TitleHowCanHelpYou: 'How can we help you?',
      SubtitleHowCanHelpYou: 'We will help you make the most of your trading through enhanced control and tracking of relevant data.',
      TitleWhatIsOurGoal: 'What is our goal?',
      SubtitleWhatIsOurGoal:
        'Our goal is to make straightforward what is difficult for others and teach you how to improve your stock and options trading abilities.',
      TitleTradeStation: 'Integrated with TradeStation',
      SubtitleTradeStation:
        'TradeStation is a broker favored by professional investors gaining traction among retail investors. Through our partnership, we offer brokerage services and access to TradeStation accounts and portfolios.',
    },
  },
  PricingSection: {
    TopTitle: 'HOW MUCH FOR A TICKET?',
    Title: 'Pricing & Features',
    DiscountBtn: 'Annual 25% OFF',
    Card1: {
      Title: 'Freemium',
      Subtitle: 'Free Forever',
      Btn: 'Get Started',
      Info1: 'Personal account to manage your portfolios',
      Info2: 'Market Scan delayed data (15m)'
    },
    Card2: {
      Title: 'Delayed Data',
      Subtitle: '/month',
      Btn: 'START NOW',
      InfoSection: {
        Info1: 'Market Scan delayed data (15m)',
        Info2: 'Ticker Scan delayed data (15m)',
        Info3: 'Option Portfolio',
        Info4: 'Fundamental Explorer',
        Info5: 'Backtester',
      },
    },
    Card3: {
      Title: 'Real-time Data',
      Subtitle: '/month',
      Btn: 'START NOW',
      InfoSection: {
        Info1: 'Market scan real-time data',
        Info2: 'Ticker scan real-time data',
        Info3: 'Options Portfolio',
        Info4: 'Fundamental Explorer',
        Info5: 'Backtester',
      },
    },
  },
  FooterSection: {
    Title: 'Trusted by...',
    li1: 'Terms & conditions',
    li2: 'Privacy policy',
    li3: 'Investment consideration',
    li4: 'Contact Us',
    Copy: 'All rights reserved',
  },
  SkyBlueEducation: {
    OptionsStrategies: 'Options Strategies',
    SkyBlueAnalyticsBtn: 'Skyblue Analytics',
    Title: 'Grow your knowledge.',
    Subtitle: 'Learn everything you need to know to become a complete options trader with us.',
    OptionsStrategiesSection: {
      Title: 'Options Strategies based on market tendencies.',
      Subtitle1: 'Learn how to time your investments and base your decision making on market tendencies.',
      Subtitle2: 'Browse some of our strategies and take note of some of our recommendations, so that when the time comes, you are ready for action.',
    },
    Bullish: {
      Title: 'Bullish',
      Hint: 'Get on board.',
      Subtitle1: 'What is a Bullish strategy?',
      Subtitle2:
        'Traders implement bullish option strategies when they believe the value of an underlying asset (ETF, Stock or Index) will increase during the length of the contract.',
      Subtitle3:
        'Since the 2008 global financial crisis, the US market has been reliably growing year after year, even during the worst pandemic of the last decades, so it is still the safest bet —all things being equal— that the stock price of US companies will increase over time.',
      Subtitle4:
        'A great variety of strategies provide traders with the best tools for the different scenarios that may arise. There are some aggressive strategies that target drastic increases in price, and others that benefit the most from just a slight increase. Strategies can require the trader to pay in order to enter (net debit strategies) or they can provide the trader with income at the beginning of the contract (net credit).',
      Subtitle5:
        'We offer the 11 most popular Bullish strategies for all levels of expertise, from deceptively simple single-leg strategies to sophisticated multi-legged strategies. Here you can find them sorted by complexity here, starting with the simplest.',
    },
    Bearish: {
      Title: 'Bearish',
      Hint: 'Get on board.',
      Subtitle1: 'What is a Bearish strategy?',
      Subtitle2:
        "ETFs, indexes and companies can lose value, or in the case of companies, even go bankrupt, making their stock price drop like Apple's in 2015, or they can straight up disappear, like Lehman Brothers, WorldCom and so many others.",
      Subtitle3:
        'The Options world is vast and you can trade with strategies that profit from a decline in price. Again, there are different strategies depending on how much you believe the market will drop during the length of the contract, the level of protection and whether you enter the contract with a credit or a net debit.',
      Subtitle4:
        'We offer the 7 most popular bearish strategies for all levels. From single-leg to multi-legged strategies, you can find them sorted by complexity here, starting with the simplest.',
    },
    Neutral: {
      Title: 'Neutral With High Vol',
      Hint: 'Get on board.',
      Subtitle1: 'What is a Neutral With High Vol. strategy?',
      Subtitle2:
        'When there is high volatility in the market and you expect a big swing in the value of an asset but do not know which direction it will go, you can use neutral strategies (not bearish nor bullish) that profit from big changes in value regardless of direction.',
      Subtitle3:
        "Generally, these strategies see the most use during earning seasons, when companies release the financial statements for their previous quarter and provide information about expected earnings, cash-flow, balance sheet info and more. This information is crucial for the market, that's why volatility increases greatly during this time.",
      Subtitle4:
        'We offer the 4 most popular neutral high-volatility strategies for all levels. All neutral strategies are necessarily multi-legged.',
    },
  },
};

// Para texto con formato <span v-html="$t('ProductsSection.OtherProducts.Card1.Subtitle')"></span>
