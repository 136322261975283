// This is just an example,
// so you can safely delete all default props below

export default {
  Nav: {
    About: 'Acerca de',
    Products: 'Productos',
    Pricing: 'Precios',
    SkyBlueEducationBtn: 'Educación Skyblue',
    LoginSignUpBtn: 'Iniciar sesión / Registrarse',
    MarketNews: 'Noticias del Mercado',
  },
  Hero: {
    Title: 'Un cielo lleno de herramientas para visualizar el mercado desde arriba.',
    Subtitle:
      'Invierte de acuerdo a las más estrictas regulaciones de Estados Unidos, con las mejores herramientas analíticas que implementan algoritmos de Inteligencia Artificial y Aprendizaje Automático aplicados a acciones, ETFs y opciones.',
    PartnersInfo: 'Ahora somos socios de',
  },
  InvestSection: {
    Title: 'Invierte con nosotros',
    Subtitle:
      'Skyblue Analytics ofrece una plataforma de trading que soporta inversiones en acciones, ETFs, contratos de opciones, bonos, futuros y criptomonedas. Utiliza nuestras herramientas para descubrir y analizar la estrategia de inversión que mejor se adapte a ti y ponla a prueba en el mercado, ya sea a través de nuestro servicio de simulación o en vivo con nuestros brokers asociados.',
  },
  ProductsSection: {
    HideProduct: 'Ocultar Producto',
    ViewFiltersUsed: 'Ver Filtros',
    AppliedFilters: 'Filtros Aplicados',
    SelectedStrategy: 'Estrategia',
    LiveDemo: 'Demo en vivo',
    LastUpdated: 'Última actualización',
    UpdatedAgo:  '',
    Title: 'Funciones y productos',
    Subtitle: 'Nuestro kit de herramientas, diseñado con productos innovadores, hará que tu trading sea más manejable, rápido y sencillo.',
    Columns:{
      StrategyName:'Estrategia',
      ProbabilityOfProfit:'PoP',
      BreakEven1:'Punto Equilibrio 1',
      BreakEven2:'Punto Equilibrio 2',
      ReturnRisk:'Retorno/Riesgo',
      MaxLoss:'Pérdida Máxima',
      MaxReturn:'Retorno Máximo',
      StrategyIV:'VI de Estrategia',
      Ticker:'Ticker',
      IVRanking52:'Ranking de VI 52S',
      IV:'VI',
      MarketCap:'Cap de Mercado',
      CurrentPrice: 'Precio Actual (Cambio)'
    },
    StrategyDetails: {
      Leg:'Pata',
      Strike:'Precio de Ejercicio',
      BidAsk:'Precio Bid/Ask (Punto Medio)',
      StrategyIV:'VI de Estrategia',
      Delta:'Delta',
      Size:'Volumen',
    },
    Card: {
      Title: 'Optimización en tiempo real para estrategias con opciones',
      Info: 'Ofrecemos un análisis rápido de datos en tiempo real y recomendaciones de estrategias personalizables para más de 2000 acciones y más de 300 ETFs en el mercado estadounidense.',
      Info2:
        'La personalización de las estrategias proviene de la implementación de configuraciones de búsqueda y filtros como fecha de vencimiento, filtro de informes de ganancias entrantes, filtros de volatilidad y liquidez, y niveles de monetización de estrategias, ya sea manuales o optimizados por Skyblue, para que el usuario pueda encontrar lo que necesita.',
      MarketClosed: 'Mercado cerrado',
      MarketScan: 'Análisis de Mercado',
      InfoFiltersBtn: 'Ver filtros utilizados',
    },
    Card2: {
      Title: 'Optimización en tiempo real para un ticker específico',
      Subtitle:
        'Puede que seas pesimista u optimista acerca de una empresa, o tal vez estés mirando una empresa con una alta volatilidad de precios y no estés seguro de qué estrategia utilizar.',
      Info: 'Con esta herramienta, encontrarás la estrategia que mejor se adapte a un solo ticker según tus criterios.',
      ViewProductBtn: 'Ver Producto',
      TickerScan: 'Análisis de Ticker',
    },
    OtherProducts: {
      Title: 'Otros productos:',
      Card1: {
        Title: 'Seguimiento de Probabilidad de Ganancia en tu Cartera',
        Subtitle:
          'Creemos que es esencial <strong>mantener un registro de nuestras operaciones</strong>, por lo que te ofrecemos la posibilidad de hacer un seeguimiento de estrategias utilizando nuestro Escáner de Mercado y Escáner de Órdenes. Puedes guardar estas estrategias en tu cartera y acceder a datos en tiempo real sobre cómo la estrategia se desempeña desde una perspectiva de <strong>griegos</strong> y <strong>valor</strong>. Puedes crear tantas <strong>carteras</strong> como necesites para rastrear la evolución de <strong>activos y contratos</strong> relevantes. Además, tendrás acceso a un gráfico diario con <strong>cambios en la Probabilidad de Ganancia</strong> para cada estrategia que rastreas.',
      },
      Card2: {
        Title: 'Explorador Fundamental',
        Subtitle:
          'Usar los datos fundamentales de una empresa para tomar decisiones es bastante desafiante, incluso para los profesionales. ¡Y es aún más difícil cuando se trata de comparar empresas! Nuestro objetivo es guiarte a través de esta gran cantidad de información de una manera fácil de usar para que puedas tomar decisiones basadas en datos claros y presentes.',
      },
    },
    Mobile: {
      Products: 'Productos',
      Text: 'Nuestro <strong>Kit de herramientas</strong>, diseñado con productos revolucionarios, hará que tu operativa en el mercado sea <strong>más sencilla</strong>, <strong>más rápida</strong> y, lo más importante, <strong>más rentable</strong>.'},
    Newsletter: {
      Title: 'Suscríbete a nuestro boletín',
      Subtitle:
        'Cada semana recibirás datos analíticos sorprendentes y útiles sobre acciones y opciones para estar actualizado con las noticias del mercado.',
      InputEmail: 'Ingresa tu correo electrónico',
      SubscribeBtn: 'Suscribirse',
    },
    BeforeTakeoff: {
      TopSubtitle: 'EL PLAN DE VUELO',
      Title: 'Antes de despegar',
      TitleDoesItWork: '¿Cómo funciona?',
      SubtitleDoesItWork:
        'Recibimos y procesamos millones de precios por segundo. Este nivel de acceso a datos, combinado con el trabajo diligente de nuestros expertos y matemáticos internos, te proporcionará información exclusiva en tiempo real para tu trading.',
      TitleHowCanHelpYou: '¿Cómo podemos ayudarte?',
      SubtitleHowCanHelpYou: 'Te ayudaremos a aprovechar al máximo tu trading mediante un mayor control y seguimiento de datos relevantes.',
      TitleWhatIsOurGoal: '¿Cuál es nuestro objetivo?',
      SubtitleWhatIsOurGoal:
        'Nuestro objetivo es hacer sencillo lo que es difícil para otros y enseñarte cómo mejorar tus habilidades de trading en acciones y opciones.',
      TitleTradeStation: 'Integrado con TradeStation',
      SubtitleTradeStation:
        'TradeStation es un broker preferido por inversores profesionales que está ganando popularidad entre los inversores minoristas. A través de nuestra asociación, ofrecemos servicios de corretaje y acceso a cuentas y carteras de TradeStation.',
    },
  },
  PricingSection: {
    TopTitle: '¿CUÁNTO CUESTA EL TICKET?',
    Title: 'Precios y características',
    DiscountBtn: '25% DE DESCUENTO ANUAL',
    Card1: {
      Title: 'Freemium',
      Subtitle: 'Gratis para siempre',
      Btn: 'Empezar',
      Info1: 'Cuenta personal para gestionar tus carteras',
      Info2: 'Análisis de Mercado con retraso (15m)',
    },
    Card2: {
      Title: 'Datos con retraso',
      Subtitle: '/mes',
      Btn: 'EMPEZAR AHORA',
      InfoSection: {
        Info1: 'Análisis de Mercado con retraso (15m)',
        Info2: 'Análisis de Ticker con retraso (15m)',
        Info3: 'Carteras de opciones y valores',
        Info4: 'Explorador de Fundamentales',
        Info5: 'Análisis Retrospectivo de Cartera',
      },
    },
    Card3: {
      Title: 'Datos en tiempo real',
      Subtitle: '/mes',
      Btn: 'EMPEZAR AHORA',
      InfoSection: {
        Info1: 'Análisis de Mercado en tiempo real',
        Info2: 'Análisis de Ticker en tiempo real',
        Info3: 'Carteras de opciones y valores',
        Info4: 'Explorador de Fundamentales',
        Info5: 'Análisis Retrospectivo de Cartera',
      },
    },
  },
  FooterSection: {
    Title: 'Confían en nosotros...',
    li1: 'Términos y condiciones',
    li2: 'Política de privacidad',
    li3: 'Consideraciones de inversión',
    li4: 'Contáctanos',
    Copy: 'Todos los derechos reservados',
  },
  SkyBlueEducation: {
    OptionsStrategies: 'Estrategias de Opciones',
    SkyBlueAnalyticsBtn: 'Analítica Skyblue',
    Title: 'Incrementa tu conocimiento.',
    Subtitle: 'Aprende todo lo que necesitas saber para convertirte en un trader completo de opciones con nosotros.',
    OptionsStrategiesSection: {
      Title: 'Estrategias de opciones basadas en tendencias del mercado.',
      Subtitle1: 'Aprende cómo sincronizar tus inversiones y basar tus decisiones en las tendencias del mercado.',
      Subtitle2:
        'Explora algunas de nuestras estrategias y toma nota de nuestras recomendaciones, para que cuando llegue el momento, estés listo para actuar.',
    },
    Bullish: {
      Title: 'Bullish',
      Hint: 'Súbete a bordo.',
      Subtitle1: '¿Qué es una estrategia bullish o alcista?',
      Subtitle2:
        'Los traders implementan estrategias alcistas cuando creen que el valor del activo subyacente (ETF, acción o índice) aumentará durante el plazo del contrato.',
      Subtitle3:
        'Desde la crisis financiera mundial de 2008, el mercado estadounidense ha crecido de manera confiable año tras año, incluso durante la peor pandemia de las últimas décadas, por lo que sigue siendo la apuesta más segura —si todo lo demás es igual— que el precio de las acciones de las empresas estadounidenses aumentará con el tiempo.',
      Subtitle4:
        'Existe una gran variedad de estrategias que proporcionan a los traders las mejores herramientas para diferentes escenarios que pueden surgir. Algunas estrategias agresivas apuntan a aumentos drásticos en el precio, mientras que otras se benefician más de un ligero aumento. Las estrategias pueden requerir que el trader pague para ingresar (estrategias con débito neto) o pueden proporcionar al trader ingresos al comienzo del contrato (crédito neto).',
      Subtitle5:
        'Ofrecemos las 11 estrategias alcistas más populares para todos los niveles de experiencia, desde estrategias sencillas de una sola pierna hasta estrategias complejas de múltiples piernas. Aquí las puedes encontrar ordenadas por complejidad, empezando por las más simples.',
    },
    Bearish: {
      Title: 'Bearish',
      Hint: 'Súbete a bordo.',
      Subtitle1: '¿Qué es una estrategia bearish o bajista?',
      Subtitle2:
        'Los ETFs, índices y empresas pueden perder valor o, en el caso de las empresas, incluso declararse en quiebra, lo que provoca una caída en el precio de sus acciones, como ocurrió con Apple en 2015, o incluso pueden desaparecer por completo, como Lehman Brothers, WorldCom y muchas otras.',
      Subtitle3:
        'El mundo de las opciones es vasto y puedes operar con estrategias que obtienen ganancias de una disminución en el precio. Nuevamente, hay diferentes estrategias según cuánto creas que caerá el mercado durante el plazo del contrato, el nivel de protección y si ingresaras al contrato con un crédito o un débito neto.',
      Subtitle4:
        'Ofrecemos las 7 estrategias bajistas más populares para todos los niveles. Desde estrategias de una sola pierna hasta estrategias de múltiples piernas, las puedes encontrar ordenadas por complejidad, empezando por las más simples.',
    },
    Neutral: {
      Title: 'Neutral con Alta Volatilidad',
      Hint: 'Súbete a bordo.',
      Subtitle1: '¿Qué es una estrategia neutral con alta volatilidad?',
      Subtitle2:
        'Cuando hay alta volatilidad en el mercado y esperas un gran cambio en el valor de un activo, pero no sabes en qué dirección se moverá, puedes utilizar estrategias neutrales (ni bajistas ni alcistas) que obtienen ganancias de grandes cambios en el valor independientemente de la dirección.',
      Subtitle3:
        'Generalmente, estas estrategias se utilizan durante la temporada de informes financieros, cuando las empresas publican los estados financieros del trimestre anterior y proporcionan información sobre las ganancias esperadas, el flujo de efectivo, el balance y más. Esta información es crucial para el mercado, por eso la volatilidad aumenta considerablemente durante este período.',
      Subtitle4:
        'Ofrecemos las 4 estrategias de alta volatilidad neutrales más populares para todos los niveles. Todas las estrategias neutrales son necesariamente de múltiples piernas.',
    },
  },
};
