export default function () {
  return {
    currentTickerInfo: {
      ticker: { symbol: 'GOOG', name: 'Alphabet Inc.' },
      priceChange: { price: '-0.24', daily: '-0.21', dailypct: '-0.21', day7: '-0.21', day7pct: '-0.38', day30: '0.77', day30pct: '1.41' },
      fundamentalData: {
        general: {
          summary: [
            { title: 'Sector', value: 'Communication Services' },
            { title: 'Industry', value: 'Internet Content & Information' },
            { title: 'Market Cap', value: '1.38T' },
            { title: 'Revenue', value: '278.14B' },
          ],
          volumes: [
            { title: 'Average Volume', value: '19.84M' },
            { title: 'Dividends (%)', value: '-' },
            { title: 'Shares Outstanding', value: '13.04B' },
            { title: 'Previous Earnings', value: '2022-07-26 (Q2)' },
            { title: 'Upcoming Earnings', value: '2022-10-25 (Q3)' },
          ],
          times: [
            { title: '52-Week High', value: 152.1 },
            { title: '52-Week Low', value: 102.208 },
            { title: 'Daily Range', value: '103.81 - 106.2' },
            { title: '1-Year Change (%)', value: '-27.28%' },
          ],
          values: [
            [
              { title: 'EPS', value: 5.44 },
              { title: 'PE', value: 10.93 },
              { title: 'BETA', value: 1.08 },
            ],
            [
              { title: 'Gross Margin', value: 157827000000 },
              { title: 'Operating Margin', value: 0.3 },
              { title: 'Net Income', value: '72.02B' },
            ],
            [
              { title: 'ROE', value: 30.27 },
              { title: 'ROA', value: 21.38 },
              { title: 'ROI', value: 27.23 },
            ],
          ],
        },
        balance: {
          annual: [
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: 'name',
                  },
                },
                { name: '2022 (Q2)', align: 'center', label: '2022 (Q2)', field: '2022 (Q2)' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: '2021', align: 'center', label: '2021', field: '2021' },
                { name: '2020', align: 'center', label: '2020', field: '2020' },
                { name: '2019', align: 'center', label: '2019', field: '2019' },
                { name: '2018', align: 'center', label: '2018', field: '2018' },
                { name: '2017', align: 'center', label: '2017', field: '2017' },
              ],
              rows: [
                {
                  2017: '197.3B',
                  2018: '232.79B',
                  2019: '275.91B',
                  2020: '319.62B',
                  2021: '359.27B',
                  name: 'Total Assets',
                  '2022 (Q2)': '355.19B',
                  'Y/Y change': '-1.14%',
                },
                {
                  2017: '124.31B',
                  2018: '135.68B',
                  2019: '152.58B',
                  2020: '174.3B',
                  2021: '188.14B',
                  name: 'Current Assets',
                  '2022 (Q2)': '172.37B',
                  'Y/Y change': '-8.38%',
                },
                {
                  2017: '101.87B',
                  2018: '109.14B',
                  2019: '119.68B',
                  2020: '136.69B',
                  2021: '139.65B',
                  name: 'Cash, Cash Equivalents & Short Term Investments',
                  '2022 (Q2)': '125B',
                  'Y/Y change': '-10.49%',
                },
                {
                  2017: '18.71B',
                  2018: '21.19B',
                  2019: '27.49B',
                  2020: '31.38B',
                  2021: '40.27B',
                  name: 'Receivables',
                  '2022 (Q2)': '37.07B',
                  'Y/Y change': '-7.94%',
                },
                {
                  2017: '749M',
                  2018: '1.11B',
                  2019: '999M',
                  2020: '728M',
                  2021: '1.17B',
                  name: 'Inventory',
                  '2022 (Q2)': '1.98B',
                  'Y/Y change': '69.23%',
                },
                {
                  2017: '72.99B',
                  2018: '97.12B',
                  2019: '123.33B',
                  2020: '145.32B',
                  2021: '171.12B',
                  name: 'Total Non Current Assets',
                  '2022 (Q2)': '182.81B',
                  'Y/Y change': '6.83%',
                },
                {
                  2017: '42.38B',
                  2018: '59.72B',
                  2019: '84.59B',
                  2020: '96.96B',
                  2021: '110.56B',
                  name: 'Net PPE',
                  '2022 (Q2)': '119.62B',
                  'Y/Y change': '8.2%',
                },
                {
                  2017: '59.65B',
                  2018: '82.51B',
                  2019: '115.15B',
                  2020: '138.67B',
                  2021: '159.97B',
                  name: 'Gross PPE',
                  '2022 (Q2)': '173.66B',
                  'Y/Y change': '8.55%',
                },
                {
                  2017: '19.44B',
                  2018: '20.11B',
                  2019: '22.6B',
                  2020: '22.62B',
                  2021: '24.37B',
                  name: 'Goodwill & Other Intangible Assets',
                  '2022 (Q2)': '25.33B',
                  'Y/Y change': '3.91%',
                },
                {
                  2017: '2.67B',
                  2018: '2.69B',
                  2019: '2.34B',
                  2020: '3.95B',
                  2021: '5.36B',
                  name: 'Other Non Current Assets',
                  '2022 (Q2)': '5.71B',
                  'Y/Y change': '6.55%',
                },
              ],
              title: 'Assets',
              graph: {
                TotalAssets: {
                  series: [355185000000, 359268000000, 319616000000, 275909000000, 232792000000],
                  label: 'Total Assets',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                TotalNonCurrentAssets: {
                  series: [182814000000, 171124000000, 145320000000, 123331000000, 97116000000],
                  label: 'Total Non-Current Assets',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                CurrentAssets: {
                  series: [172371000000, 188143000000, 174296000000, 152578000000, 135676000000],
                  label: 'Current Assets',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                CashCashEquivalentsAndShortTermInvestments: {
                  series: [124997000000, 139649000000, 136694000000, 119675000000, 109140000000],
                  label: 'Cash Equivalents And Short Term Investments',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 35,
                    },
                  },
                },
                { name: '2022 (Q2)', align: 'center', label: '2022 (Q2)', field: '2022 (Q2)' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: '2021', align: 'center', label: '2021', field: '2021' },
                { name: '2020', align: 'center', label: '2020', field: '2020' },
                { name: '2019', align: 'center', label: '2019', field: '2019' },
                { name: '2018', align: 'center', label: '2018', field: '2018' },
                { name: '2017', align: 'center', label: '2017', field: '2017' },
              ],
              rows: [
                {
                  2017: '44.79B',
                  2018: '55.16B',
                  2019: '74.47B',
                  2020: '97.07B',
                  2021: '107.63B',
                  name: 'Total Liabilities',
                  '2022 (Q2)': '99.77B',
                  'Y/Y change': '-7.31%',
                },
                {
                  2017: '24.18B',
                  2018: '34.62B',
                  2019: '45.22B',
                  2020: '56.83B',
                  2021: '64.25B',
                  name: 'Current Liabilities',
                  '2022 (Q2)': '61.35B',
                  'Y/Y change': '-4.51%',
                },
                {
                  2017: '15.3B',
                  2018: '18.24B',
                  2019: '24.21B',
                  2020: '31.1B',
                  2021: '35.09B',
                  name: 'Payables & Accrued Expenses',
                  '2022 (Q2)': '35.74B',
                  'Y/Y change': '1.86%',
                },
                {
                  2017: '4.02B',
                  2018: '4.45B',
                  2019: '5.84B',
                  2020: '7.83B',
                  2021: '7.24B',
                  name: 'Payables',
                  '2022 (Q2)': '6.37B',
                  'Y/Y change': '-12.11%',
                },
                {
                  2017: '-',
                  2018: '-',
                  2019: '1.2B',
                  2020: '1.69B',
                  2021: '2.19B',
                  name: 'Current Debt & Capital Lease Obligation',
                  '2022 (Q2)': '2.3B',
                  'Y/Y change': '4.93%',
                },
                {
                  2017: '2.87B',
                  2018: '7.75B',
                  2019: '9.41B',
                  2020: '10.41B',
                  2021: '9.8B',
                  name: 'Other Current Liabilities',
                  '2022 (Q2)': '9.19B',
                  'Y/Y change': '-6.2%',
                },
                {
                  2017: '20.61B',
                  2018: '20.54B',
                  2019: '29.25B',
                  2020: '40.24B',
                  2021: '43.38B',
                  name: 'Total Non Current Liabilities',
                  '2022 (Q2)': '38.41B',
                  'Y/Y change': '-11.45%',
                },
                {
                  2017: '3.97B',
                  2018: '4.01B',
                  2019: '14.77B',
                  2020: '25.08B',
                  2021: '26.21B',
                  name: 'Long Term Debt & Capital Lease Obligation',
                  '2022 (Q2)': '26.43B',
                  'Y/Y change': '0.86%',
                },
                {
                  2017: '430M',
                  2018: '1.26B',
                  2019: '1.7B',
                  2020: '3.56B',
                  2021: '5.26B',
                  name: 'Non Current Deferred Taxes Liabilities',
                  '2022 (Q2)': '924M',
                  'Y/Y change': '-82.42%',
                },
              ],
              title: 'Liabilities',
              graph: {
                TotalLiabilities: {
                  series: [99766000000, 107633000000, 97072000000, 74467000000, 55164000000],
                  label: 'Total Liabilities',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                CurrentLiabilities: {
                  series: [61354000000, 64254000000, 56834000000, 45221000000, 34620000000],
                  label: 'Current Liabilities',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                TotalNonCurrentLiabilities: {
                  series: [38412000000, 43379000000, 40238000000, 29246000000, 20544000000],
                  label: 'Total Non-Current Liabilities',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                PayablesAndAccruedExpenses: {
                  series: [35742000000, 35089000000, 31102000000, 24214000000, 18243000000],
                  label: 'Payables And Accrued Expenses',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                LongTermDebt: {
                  series: [12850000000, 12844000000, 12832000000, 4554000000, 3950000000],
                  label: 'Long Term Debt',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 36,
                    },
                  },
                },
                { name: '2022 (Q2)', align: 'center', label: '2022 (Q2)', field: '2022 (Q2)' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: '2021', align: 'center', label: '2021', field: '2021' },
                { name: '2020', align: 'center', label: '2020', field: '2020' },
                { name: '2019', align: 'center', label: '2019', field: '2019' },
                { name: '2018', align: 'center', label: '2018', field: '2018' },
                { name: '2017', align: 'center', label: '2017', field: '2017' },
              ],
              rows: [
                {
                  2017: '40.25B',
                  2018: '45.05B',
                  2019: '50.55B',
                  2020: '58.51B',
                  2021: '61.77B',
                  name: 'Capital Stock',
                  '2022 (Q2)': '64.4B',
                  'Y/Y change': '4.25%',
                },
                {
                  2017: '40.25B',
                  2018: '45.05B',
                  2019: '50.55B',
                  2020: '58.51B',
                  2021: '61.77B',
                  name: 'Common Stock',
                  '2022 (Q2)': '64.4B',
                  'Y/Y change': '4.25%',
                },
                {
                  2017: '113.25B',
                  2018: '134.89B',
                  2019: '152.12B',
                  2020: '163.4B',
                  2021: '191.48B',
                  name: 'Retained Earnings',
                  '2022 (Q2)': '196.85B',
                  'Y/Y change': '2.8%',
                },
                {
                  2017: '156.45B',
                  2018: '181.58B',
                  2019: '206B',
                  2020: '235.38B',
                  2021: '264.48B',
                  name: 'Total Capitalization',
                  '2022 (Q2)': '268.27B',
                  'Y/Y change': '1.43%',
                },
              ],
              title: 'Equity',
              graph: {
                StockholdersEquity: {
                  series: [255419000000, 251635000000, 222544000000, 201442000000, 177628000000],
                  label: 'Stockholders Equity',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                RetainedEarnings: {
                  series: [196845000000, 191484000000, 163401000000, 152122000000, 134885000000],
                  label: 'Retained Earnings',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
              },
            },
          ],
          quarterly: [
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 37,
                    },
                  },
                },
                { name: 'Q2 2022', align: 'center', label: 'Q2 2022', field: 'Q2 2022' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: 'Q1 2022', align: 'center', label: 'Q1 2022', field: 'Q1 2022' },
                { name: 'Q4 2021', align: 'center', label: 'Q4 2021', field: 'Q4 2021' },
                { name: 'Q3 2021', align: 'center', label: 'Q3 2021', field: 'Q3 2021' },
                { name: 'Q2 2021', align: 'center', label: 'Q2 2021', field: 'Q2 2021' },
              ],
              rows: [
                {
                  name: 'Total Assets',
                  'Q2 2022': '355.19B',
                  'Y/Y change': '5.9%',
                  'Q1 2022': '357.1B',
                  'Q4 2021': '359.27B',
                  'Q3 2021': '347.4B',
                  'Q2 2021': '335.39B',
                },
                {
                  name: 'Current Assets',
                  'Q2 2022': '172.37B',
                  'Y/Y change': '-1.89%',
                  'Q1 2022': '177.85B',
                  'Q4 2021': '188.14B',
                  'Q3 2021': '184.11B',
                  'Q2 2021': '175.7B',
                },
                {
                  name: 'Cash, Cash Equivalents & Short Term Investments',
                  'Q2 2022': '125B',
                  'Y/Y change': '-8%',
                  'Q1 2022': '133.97B',
                  'Q4 2021': '139.65B',
                  'Q3 2021': '142B',
                  'Q2 2021': '135.86B',
                },
                {
                  name: 'Receivables',
                  'Q2 2022': '37.07B',
                  'Y/Y change': '12.85%',
                  'Q1 2022': '35.62B',
                  'Q4 2021': '40.27B',
                  'Q3 2021': '34.8B',
                  'Q2 2021': '32.85B',
                },
                {
                  name: 'Inventory',
                  'Q2 2022': '1.98B',
                  'Y/Y change': '118.3%',
                  'Q1 2022': '1.37B',
                  'Q4 2021': '1.17B',
                  'Q3 2021': '1.28B',
                  'Q2 2021': '907M',
                },
                {
                  name: 'Total Non Current Assets',
                  'Q2 2022': '182.81B',
                  'Y/Y change': '14.48%',
                  'Q1 2022': '179.24B',
                  'Q4 2021': '171.12B',
                  'Q3 2021': '163.29B',
                  'Q2 2021': '159.69B',
                },
                {
                  name: 'Net PPE',
                  'Q2 2022': '119.62B',
                  'Y/Y change': '14.28%',
                  'Q1 2022': '117.21B',
                  'Q4 2021': '110.56B',
                  'Q3 2021': '107.55B',
                  'Q2 2021': '104.68B',
                },
                {
                  name: 'Gross PPE',
                  'Q2 2022': '173.66B',
                  'Y/Y change': '15.73%',
                  'Q1 2022': '169.17B',
                  'Q4 2021': '159.97B',
                  'Q3 2021': '154.82B',
                  'Q2 2021': '150.05B',
                },
                {
                  name: 'Goodwill & Other Intangible Assets',
                  'Q2 2022': '25.33B',
                  'Y/Y change': '5.38%',
                  'Q1 2022': '24.32B',
                  'Q4 2021': '24.37B',
                  'Q3 2021': '24.17B',
                  'Q2 2021': '24.03B',
                },
                {
                  name: 'Other Non Current Assets',
                  'Q2 2022': '5.71B',
                  'Y/Y change': '32.9%',
                  'Q1 2022': '5.78B',
                  'Q4 2021': '5.36B',
                  'Q3 2021': '4.28B',
                  'Q2 2021': '4.3B',
                },
              ],
              title: 'Assets',
              graph: {
                TotalAssets: {
                  series: [355185000000, 357096000000, 359268000000, 347403000000, 335387000000],
                  label: 'Total Assets',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                TotalNonCurrentAssets: {
                  series: [182814000000, 179243000000, 171124000000, 163293000000, 159690000000],
                  label: 'Total Non-Current Assets',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                CurrentAssets: {
                  series: [172371000000, 177853000000, 188143000000, 184110000000, 175697000000],
                  label: 'Current Assets',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                CashCashEquivalentsAndShortTermInvestments: {
                  series: [124997000000, 133970000000, 139649000000, 142003000000, 135863000000],
                  label: 'Cash Equivalents And Short Term Investments',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 38,
                    },
                  },
                },
                { name: 'Q2 2022', align: 'center', label: 'Q2 2022', field: 'Q2 2022' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: 'Q1 2022', align: 'center', label: 'Q1 2022', field: 'Q1 2022' },
                { name: 'Q4 2021', align: 'center', label: 'Q4 2021', field: 'Q4 2021' },
                { name: 'Q3 2021', align: 'center', label: 'Q3 2021', field: 'Q3 2021' },
                { name: 'Q2 2021', align: 'center', label: 'Q2 2021', field: 'Q2 2021' },
              ],
              rows: [
                {
                  name: 'Total Liabilities',
                  'Q2 2022': '99.77B',
                  'Y/Y change': '1.99%',
                  'Q1 2022': '103.09B',
                  'Q4 2021': '107.63B',
                  'Q3 2021': '102.84B',
                  'Q2 2021': '97.82B',
                },
                {
                  name: 'Current Liabilities',
                  'Q2 2022': '61.35B',
                  'Y/Y change': '10.07%',
                  'Q1 2022': '61.95B',
                  'Q4 2021': '64.25B',
                  'Q3 2021': '61.78B',
                  'Q2 2021': '55.74B',
                },
                {
                  name: 'Payables & Accrued Expenses',
                  'Q2 2022': '35.74B',
                  'Y/Y change': '16.62%',
                  'Q1 2022': '37.01B',
                  'Q4 2021': '35.09B',
                  'Q3 2021': '34.47B',
                  'Q2 2021': '30.65B',
                },
                {
                  name: 'Payables',
                  'Q2 2022': '6.37B',
                  'Y/Y change': '-14.96%',
                  'Q1 2022': '7.78B',
                  'Q4 2021': '7.24B',
                  'Q3 2021': '10.3B',
                  'Q2 2021': '7.49B',
                },
                {
                  name: 'Current Debt & Capital Lease Obligation',
                  'Q2 2022': '2.3B',
                  'Y/Y change': '12.76%',
                  'Q1 2022': '2.27B',
                  'Q4 2021': '2.19B',
                  'Q3 2021': '2.1B',
                  'Q2 2021': '2.04B',
                },
                {
                  name: 'Other Current Liabilities',
                  'Q2 2022': '9.19B',
                  'Y/Y change': '-10.37%',
                  'Q1 2022': '9.67B',
                  'Q4 2021': '9.8B',
                  'Q3 2021': '10.07B',
                  'Q2 2021': '10.25B',
                },
                {
                  name: 'Total Non Current Liabilities',
                  'Q2 2022': '38.41B',
                  'Y/Y change': '-8.72%',
                  'Q1 2022': '41.14B',
                  'Q4 2021': '43.38B',
                  'Q3 2021': '41.05B',
                  'Q2 2021': '42.08B',
                },
                {
                  name: 'Long Term Debt & Capital Lease Obligation',
                  'Q2 2022': '26.43B',
                  'Y/Y change': '1.87%',
                  'Q1 2022': '26.15B',
                  'Q4 2021': '26.21B',
                  'Q3 2021': '25.76B',
                  'Q2 2021': '25.95B',
                },
                {
                  name: 'Non Current Deferred Taxes Liabilities',
                  'Q2 2022': '924M',
                  'Y/Y change': '-80.35%',
                  'Q1 2022': '2.84B',
                  'Q4 2021': '5.26B',
                  'Q3 2021': '3.55B',
                  'Q2 2021': '4.7B',
                },
              ],
              title: 'Liabilities',
              graph: {
                TotalLiabilities: {
                  series: [99766000000, 103092000000, 107633000000, 102836000000, 97822000000],
                  label: 'Total Liabilities',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                CurrentLiabilities: {
                  series: [61354000000, 61948000000, 64254000000, 61782000000, 55741000000],
                  label: 'Current Liabilities',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                TotalNonCurrentLiabilities: {
                  series: [38412000000, 41144000000, 43379000000, 41054000000, 42081000000],
                  label: 'Total Non-Current Liabilities',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                PayablesAndAccruedExpenses: {
                  series: [35742000000, 37010000000, 35089000000, 34470000000, 30647000000],
                  label: 'Payables And Accrued Expenses',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                LongTermDebt: {
                  series: [12850000000, 12847000000, 12844000000, 14288000000, 14328000000],
                  label: 'Long Term Debt',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 39,
                    },
                  },
                },
                { name: 'Q2 2022', align: 'center', label: 'Q2 2022', field: 'Q2 2022' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: 'Q1 2022', align: 'center', label: 'Q1 2022', field: 'Q1 2022' },
                { name: 'Q4 2021', align: 'center', label: 'Q4 2021', field: 'Q4 2021' },
                { name: 'Q3 2021', align: 'center', label: 'Q3 2021', field: 'Q3 2021' },
                { name: 'Q2 2021', align: 'center', label: 'Q2 2021', field: 'Q2 2021' },
              ],
              rows: [
                {
                  name: 'Capital Stock',
                  'Q2 2022': '64.4B',
                  'Y/Y change': '6.56%',
                  'Q1 2022': '62.83B',
                  'Q4 2021': '61.77B',
                  'Q3 2021': '61.19B',
                  'Q2 2021': '60.44B',
                },
                {
                  name: 'Common Stock',
                  'Q2 2022': '64.4B',
                  'Y/Y change': '6.56%',
                  'Q1 2022': '62.83B',
                  'Q4 2021': '61.77B',
                  'Q3 2021': '61.19B',
                  'Q2 2021': '60.44B',
                },
                {
                  name: 'Retained Earnings',
                  'Q2 2022': '196.85B',
                  'Y/Y change': '11.25%',
                  'Q1 2022': '195.22B',
                  'Q4 2021': '191.48B',
                  'Q3 2021': '183.78B',
                  'Q2 2021': '176.94B',
                },
                {
                  name: 'Unrealized Gain Loss',
                  'Q2 2022': '-',
                  'Y/Y change': '-',
                  'Q1 2022': '-',
                  'Q4 2021': '-',
                  'Q3 2021': '-',
                  'Q2 2021': '1.01B',
                },
                {
                  name: 'Total Capitalization',
                  'Q2 2022': '268.27B',
                  'Y/Y change': '6.5%',
                  'Q1 2022': '266.85B',
                  'Q4 2021': '264.48B',
                  'Q3 2021': '258.86B',
                  'Q2 2021': '251.89B',
                },
              ],
              title: 'Equity',
              graph: {
                StockholdersEquity: {
                  series: [255419000000, 254004000000, 251635000000, 244567000000, 237565000000],
                  label: 'Stockholders Equity',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                RetainedEarnings: {
                  series: [196845000000, 195221000000, 191484000000, 183782000000, 176939000000],
                  label: 'Retained Earnings',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
              },
            },
          ],
        },
        cashflow: {
          annual: [
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 40,
                    },
                  },
                },
                { name: '2022 (Q2)', align: 'center', label: '2022 (Q2)', field: '2022 (Q2)' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: '2021', align: 'center', label: '2021', field: '2021' },
                { name: '2020', align: 'center', label: '2020', field: '2020' },
                { name: '2019', align: 'center', label: '2019', field: '2019' },
                { name: '2018', align: 'center', label: '2018', field: '2018' },
                { name: '2017', align: 'center', label: '2017', field: '2017' },
              ],
              rows: [
                {
                  2017: '37.09B',
                  2018: '47.97B',
                  2019: '54.52B',
                  2020: '65.12B',
                  2021: '91.65B',
                  name: 'Operating Cash Flow',
                  '2022 (Q2)': '95B',
                  'Y/Y change': '3.65%',
                },
                {
                  2017: '37.09B',
                  2018: '47.97B',
                  2019: '54.52B',
                  2020: '65.12B',
                  2021: '91.65B',
                  name: 'Cash Flow From Continuing Operating Activities',
                  '2022 (Q2)': '95B',
                  'Y/Y change': '3.65%',
                },
                {
                  2017: '12.66B',
                  2018: '30.74B',
                  2019: '34.34B',
                  2020: '40.27B',
                  2021: '76.03B',
                  name: 'Net Income From Continuing Operations',
                  '2022 (Q2)': '72.02B',
                  'Y/Y change': '-5.28%',
                },
                {
                  2017: '6.92B',
                  2018: '9.04B',
                  2019: '11.78B',
                  2020: '13.7B',
                  2021: '12.44B',
                  name: 'Depreciation & Amortization',
                  '2022 (Q2)': '14.42B',
                  'Y/Y change': '15.94%',
                },
                {
                  2017: '9.25B',
                  2018: '4.91B',
                  2019: '819M',
                  2020: '1.83B',
                  2021: '-1.52B',
                  name: 'Change In Working Capital',
                  '2022 (Q2)': '-2.74B',
                  'Y/Y change': '-79.84%',
                },
                {
                  2017: '-3.77B',
                  2018: '-2.17B',
                  2019: '-4.34B',
                  2020: '-6.52B',
                  2021: '-9.1B',
                  name: 'Change In Receivables',
                  '2022 (Q2)': '-5.83B',
                  'Y/Y change': '35.87%',
                },
                {
                  2017: '731M',
                  2018: '1.07B',
                  2019: '428M',
                  2020: '694M',
                  2021: '283M',
                  name: 'Change In Payable',
                  '2022 (Q2)': '223M',
                  'Y/Y change': '-21.2%',
                },
                {
                  2017: '731M',
                  2018: '1.07B',
                  2019: '428M',
                  2020: '694M',
                  2021: '283M',
                  name: 'Change In Account Payable',
                  '2022 (Q2)': '223M',
                  'Y/Y change': '-21.2%',
                },
              ],
              title: 'Operating',
              graph: {
                OperatingCashFlow: {
                  series: [95001000000, 91652000000, 65124000000, 54520000000, 47971000000],
                  label: 'Operating Cash Flow',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                ChangeInWorkingCapital: {
                  series: [-2739000000, -1523000000, 1827000000, 819000000, 4908000000],
                  label: 'Change In Working Capital',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 41,
                    },
                  },
                },
                { name: '2022 (Q2)', align: 'center', label: '2022 (Q2)', field: '2022 (Q2)' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: '2021', align: 'center', label: '2021', field: '2021' },
                { name: '2020', align: 'center', label: '2020', field: '2020' },
                { name: '2019', align: 'center', label: '2019', field: '2019' },
                { name: '2018', align: 'center', label: '2018', field: '2018' },
                { name: '2017', align: 'center', label: '2017', field: '2017' },
              ],
              rows: [
                {
                  2017: '-8.3B',
                  2018: '-13.18B',
                  2019: '-23.21B',
                  2020: '-24.41B',
                  2021: '-61.36B',
                  name: 'Financing Cash Flow',
                  '2022 (Q2)': '-65.8B',
                  'Y/Y change': '-7.23%',
                },
                {
                  2017: '-86M',
                  2018: '-61M',
                  2019: '-268M',
                  2020: '9.66B',
                  2021: '-1.24B',
                  name: 'Net Issuance Payments Of Debt',
                  '2022 (Q2)': '-511M',
                  'Y/Y change': '58.66%',
                },
                {
                  2017: '-86M',
                  2018: '-61M',
                  2019: '-268M',
                  2020: '9.66B',
                  2021: '-1.24B',
                  name: 'Net Long Term Debt Issuance',
                  '2022 (Q2)': '-511M',
                  'Y/Y change': '58.66%',
                },
                {
                  2017: '4.29B',
                  2018: '6.77B',
                  2019: '317M',
                  2020: '11.76B',
                  2021: '20.2B',
                  name: 'Long Term Debt Issuance',
                  '2022 (Q2)': '41.83B',
                  'Y/Y change': '107.08%',
                },
                {
                  2017: '-4.38B',
                  2018: '-6.83B',
                  2019: '-585M',
                  2020: '-2.1B',
                  2021: '-21.44B',
                  name: 'Long Term Debt Payments',
                  '2022 (Q2)': '-42.34B',
                  'Y/Y change': '-97.52%',
                },
                {
                  2017: '-4.85B',
                  2018: '-9.08B',
                  2019: '-18.4B',
                  2020: '-31.15B',
                  2021: '-50.27B',
                  name: 'Net Common Stock Issuance',
                  '2022 (Q2)': '-54.58B',
                  'Y/Y change': '-8.57%',
                },
                { 2017: '800M', 2018: '950M', 2019: '-', 2020: '-', 2021: '-', name: 'Common Stock Issuance', '2022 (Q2)': '-', 'Y/Y change': '-' },
                {
                  2017: '-4.85B',
                  2018: '-9.08B',
                  2019: '-18.4B',
                  2020: '-31.15B',
                  2021: '-50.27B',
                  name: 'Common Stock Payments',
                  '2022 (Q2)': '-54.58B',
                  'Y/Y change': '-8.57%',
                },
              ],
              title: 'Financing',
              graph: {
                NetIssuancePaymentsOfDebt: {
                  series: [-511000000, -1236000000, 9661000000, -268000000, -61000000],
                  label: 'Net Issuance Payments Of Debt',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                NetLongTermDebtIssuance: {
                  series: [-511000000, -1236000000, 9661000000, -268000000, -61000000],
                  label: 'Net Long Term Debt Issuance',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                NetCommonStockIssuance: {
                  series: [-54580000000, -50274000000, -31149000000, -18396000000, -9075000000],
                  label: 'Net Common Stock Issuance',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                FinancingCashFlow: {
                  series: [-65796000000, -61362000000, -24408000000, -23209000000, -13179000000],
                  label: 'Financing Cash Flow',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 42,
                    },
                  },
                },
                { name: '2022 (Q2)', align: 'center', label: '2022 (Q2)', field: '2022 (Q2)' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: '2021', align: 'center', label: '2021', field: '2021' },
                { name: '2020', align: 'center', label: '2020', field: '2020' },
                { name: '2019', align: 'center', label: '2019', field: '2019' },
                { name: '2018', align: 'center', label: '2018', field: '2018' },
                { name: '2017', align: 'center', label: '2017', field: '2017' },
              ],
              rows: [
                {
                  2017: '-13.18B',
                  2018: '-25.14B',
                  2019: '-23.55B',
                  2020: '-22.28B',
                  2021: '-24.64B',
                  name: 'Capital Expenditure',
                  '2022 (Q2)': '-29.82B',
                  'Y/Y change': '-21.01%',
                },
                {
                  2017: '-13.18B',
                  2018: '-25.14B',
                  2019: '-23.55B',
                  2020: '-22.28B',
                  2021: '-24.64B',
                  name: 'Net PPE Purchase & Sale',
                  '2022 (Q2)': '-29.82B',
                  'Y/Y change': '-21.01%',
                },
                {
                  2017: '-13.18B',
                  2018: '-25.14B',
                  2019: '-23.55B',
                  2020: '-22.28B',
                  2021: '-24.64B',
                  name: 'Purchase Of PPE',
                  '2022 (Q2)': '-29.82B',
                  'Y/Y change': '-21.01%',
                },
                { 2017: '99M', 2018: '98M', 2019: '-', 2020: '-', 2021: '-', name: 'Sale Of PPE', '2022 (Q2)': '-', 'Y/Y change': '-' },
                {
                  2017: '-287M',
                  2018: '-1.49B',
                  2019: '-2.52B',
                  2020: '-738M',
                  2021: '-2.62B',
                  name: 'Net Business Purchase & Sale',
                  '2022 (Q2)': '-1.88B',
                  'Y/Y change': '28.19%',
                },
                {
                  2017: '-287M',
                  2018: '-1.49B',
                  2019: '-2.52B',
                  2020: '-738M',
                  2021: '-2.62B',
                  name: 'Purchase Of Business',
                  '2022 (Q2)': '-1.88B',
                  'Y/Y change': '28.19%',
                },
                {
                  2017: '-19.45B',
                  2018: '-1.97B',
                  2019: '-4.02B',
                  2020: '-9.82B',
                  2021: '-8.81B',
                  name: 'Net Investment Purchase & Sale',
                  '2022 (Q2)': '-3.67B',
                  'Y/Y change': '58.3%',
                },
                {
                  2017: '1.52B',
                  2018: '98M',
                  2019: '589M',
                  2020: '68M',
                  2021: '541M',
                  name: 'Net Other Investing Changes',
                  '2022 (Q2)': '1.06B',
                  'Y/Y change': '96.67%',
                },
                {
                  2017: '-93.94B',
                  2018: '-52.23B',
                  2019: '-102.25B',
                  2020: '-143.75B',
                  2021: '-138.03B',
                  name: 'Purchase Of Investment',
                  '2022 (Q2)': '-127.48B',
                  'Y/Y change': '7.65%',
                },
                {
                  2017: '74.49B',
                  2018: '50.26B',
                  2019: '98.23B',
                  2020: '133.93B',
                  2021: '129.23B',
                  name: 'Sale Of Investment',
                  '2022 (Q2)': '123.8B',
                  'Y/Y change': '-4.2%',
                },
              ],
              title: 'Investing',
              graph: {
                NetInvestmentPurchaseAndSale: {
                  series: [-3672000000, -8806000000, -9822000000, -4017000000, -1972000000],
                  label: 'Net Investment Purchase And Sale',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                CapitalExpenditure: {
                  series: [-29816000000, -24640000000, -22281000000, -23548000000, -25139000000],
                  label: 'Capital Expenditure',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                NetPPEPurchaseAndSale: {
                  series: [-29816000000, -24640000000, -22281000000, -23548000000, -25139000000],
                  label: 'Net PPE Purchase And Sale',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                InvestingCashFlow: {
                  series: [-34304000000, -35523000000, -32773000000, -29491000000, -28504000000],
                  label: 'Investing Cash Flow',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 43,
                    },
                  },
                },
                { name: '2022 (Q2)', align: 'center', label: '2022 (Q2)', field: '2022 (Q2)' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: '2021', align: 'center', label: '2021', field: '2021' },
                { name: '2020', align: 'center', label: '2020', field: '2020' },
                { name: '2019', align: 'center', label: '2019', field: '2019' },
                { name: '2018', align: 'center', label: '2018', field: '2018' },
                { name: '2017', align: 'center', label: '2017', field: '2017' },
              ],
              rows: [
                {
                  2017: '405M',
                  2018: '-302M',
                  2019: '-23M',
                  2020: '24M',
                  2021: '-287M',
                  name: 'Effect Of Exchange Rate Changes',
                  '2022 (Q2)': '-595M',
                  'Y/Y change': '-107.32%',
                },
                {
                  2017: '12.92B',
                  2018: '10.72B',
                  2019: '16.7B',
                  2020: '18.5B',
                  2021: '26.47B',
                  name: 'Beginning Cash Position',
                  '2022 (Q2)': '23.63B',
                  'Y/Y change': '-10.71%',
                },
                {
                  2017: '10.72B',
                  2018: '16.7B',
                  2019: '18.5B',
                  2020: '26.47B',
                  2021: '20.95B',
                  name: 'End Cash Position',
                  '2022 (Q2)': '17.94B',
                  'Y/Y change': '-14.37%',
                },
                {
                  2017: '-2.61B',
                  2018: '6.29B',
                  2019: '1.82B',
                  2020: '7.94B',
                  2021: '-5.23B',
                  name: 'Changes In Cash',
                  '2022 (Q2)': '-5.1B',
                  'Y/Y change': '2.56%',
                },
              ],
              title: 'Net',
              graph: {
                BeginningCashPosition: {
                  series: [23630000000, 26465000000, 18498000000, 16701000000, 10715000000],
                  label: 'Beginning Cash Position',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                EndCashPosition: {
                  series: [17936000000, 20945000000, 26465000000, 18498000000, 16701000000],
                  label: 'End Cash Position',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                ChangesInCash: {
                  series: [-5099000000, -5233000000, 7943000000, 1820000000, 6288000000],
                  label: 'Changes In Cash',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
              },
            },
          ],
          quarterly: [
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 44,
                    },
                  },
                },
                { name: 'Q2 2022', align: 'center', label: 'Q2 2022', field: 'Q2 2022' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: 'Q1 2022', align: 'center', label: 'Q1 2022', field: 'Q1 2022' },
                { name: 'Q4 2021', align: 'center', label: 'Q4 2021', field: 'Q4 2021' },
                { name: 'Q3 2021', align: 'center', label: 'Q3 2021', field: 'Q3 2021' },
                { name: 'Q2 2021', align: 'center', label: 'Q2 2021', field: 'Q2 2021' },
              ],
              rows: [
                {
                  name: 'Operating Cash Flow',
                  'Q2 2022': '19.42B',
                  'Y/Y change': '-11.27%',
                  'Q1 2022': '25.11B',
                  'Q4 2021': '24.93B',
                  'Q3 2021': '25.54B',
                  'Q2 2021': '21.89B',
                },
                {
                  name: 'Cash Flow From Continuing Operating Activities',
                  'Q2 2022': '19.42B',
                  'Y/Y change': '-11.27%',
                  'Q1 2022': '25.11B',
                  'Q4 2021': '24.93B',
                  'Q3 2021': '25.54B',
                  'Q2 2021': '21.89B',
                },
                {
                  name: 'Net Income From Continuing Operations',
                  'Q2 2022': '16B',
                  'Y/Y change': '-13.62%',
                  'Q1 2022': '16.44B',
                  'Q4 2021': '20.64B',
                  'Q3 2021': '18.94B',
                  'Q2 2021': '18.53B',
                },
                {
                  name: 'Depreciation & Amortization',
                  'Q2 2022': '3.9B',
                  'Y/Y change': '32.39%',
                  'Q1 2022': '3.78B',
                  'Q4 2021': '3.44B',
                  'Q3 2021': '3.3B',
                  'Q2 2021': '2.95B',
                },
                {
                  name: 'Change In Working Capital',
                  'Q2 2022': '-4.22B',
                  'Y/Y change': '-384.16%',
                  'Q1 2022': '897M',
                  'Q4 2021': '-2.23B',
                  'Q3 2021': '2.81B',
                  'Q2 2021': '-871M',
                },
                {
                  name: 'Change In Receivables',
                  'Q2 2022': '-1.97B',
                  'Y/Y change': '46.22%',
                  'Q1 2022': '4.36B',
                  'Q4 2021': '-5.82B',
                  'Q3 2021': '-2.41B',
                  'Q2 2021': '-3.66B',
                },
                {
                  name: 'Change In Payable',
                  'Q2 2022': '1.2B',
                  'Y/Y change': '1023.85%',
                  'Q1 2022': '-2.37B',
                  'Q4 2021': '1.16B',
                  'Q3 2021': '238M',
                  'Q2 2021': '-130M',
                },
                {
                  name: 'Change In Account Payable',
                  'Q2 2022': '1.2B',
                  'Y/Y change': '1023.85%',
                  'Q1 2022': '-2.37B',
                  'Q4 2021': '1.16B',
                  'Q3 2021': '238M',
                  'Q2 2021': '-130M',
                },
              ],
              title: 'Operating',
              graph: {
                OperatingCashFlow: {
                  series: [19422000000, 25106000000, 24934000000, 25539000000, 21890000000],
                  label: 'Operating Cash Flow',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                ChangeInWorkingCapital: {
                  series: [-4217000000, 897000000, -2225000000, 2806000000, -871000000],
                  label: 'Change In Working Capital',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 45,
                    },
                  },
                },
                { name: 'Q2 2022', align: 'center', label: 'Q2 2022', field: 'Q2 2022' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: 'Q1 2022', align: 'center', label: 'Q1 2022', field: 'Q1 2022' },
                { name: 'Q4 2021', align: 'center', label: 'Q4 2021', field: 'Q4 2021' },
                { name: 'Q3 2021', align: 'center', label: 'Q3 2021', field: 'Q3 2021' },
                { name: 'Q2 2021', align: 'center', label: 'Q2 2021', field: 'Q2 2021' },
              ],
              rows: [
                {
                  name: 'Financing Cash Flow',
                  'Q2 2022': '-17.82B',
                  'Y/Y change': '-11.42%',
                  'Q1 2022': '-16.21B',
                  'Q4 2021': '-16.51B',
                  'Q3 2021': '-15.25B',
                  'Q2 2021': '-15.99B',
                },
                {
                  name: 'Net Issuance Payments Of Debt',
                  'Q2 2022': '-356M',
                  'Y/Y change': '65.83%',
                  'Q1 2022': '2M',
                  'Q4 2021': '-115M',
                  'Q3 2021': '-42M',
                  'Q2 2021': '-1.04B',
                },
                {
                  name: 'Net Long Term Debt Issuance',
                  'Q2 2022': '-356M',
                  'Y/Y change': '65.83%',
                  'Q1 2022': '2M',
                  'Q4 2021': '-115M',
                  'Q3 2021': '-42M',
                  'Q2 2021': '-1.04B',
                },
                {
                  name: 'Long Term Debt Issuance',
                  'Q2 2022': '12.81B',
                  'Y/Y change': '91.16%',
                  'Q1 2022': '16.42B',
                  'Q4 2021': '6.25B',
                  'Q3 2021': '6.35B',
                  'Q2 2021': '6.7B',
                },
                {
                  name: 'Long Term Debt Payments',
                  'Q2 2022': '-13.16B',
                  'Y/Y change': '-70.03%',
                  'Q1 2022': '-16.42B',
                  'Q4 2021': '-6.37B',
                  'Q3 2021': '-6.39B',
                  'Q2 2021': '-7.74B',
                },
                {
                  name: 'Net Common Stock Issuance',
                  'Q2 2022': '-15.2B',
                  'Y/Y change': '-18.76%',
                  'Q1 2022': '-13.3B',
                  'Q4 2021': '-13.47B',
                  'Q3 2021': '-12.61B',
                  'Q2 2021': '-12.8B',
                },
                {
                  name: 'Common Stock Payments',
                  'Q2 2022': '-15.2B',
                  'Y/Y change': '-18.76%',
                  'Q1 2022': '-13.3B',
                  'Q4 2021': '-13.47B',
                  'Q3 2021': '-12.61B',
                  'Q2 2021': '-12.8B',
                },
              ],
              title: 'Financing',
              graph: {
                NetIssuancePaymentsOfDebt: {
                  series: [-356000000, 2000000, -115000000, -42000000, -1042000000],
                  label: 'Net Issuance Payments Of Debt',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                NetLongTermDebtIssuance: {
                  series: [-356000000, 2000000, -115000000, -42000000, -1042000000],
                  label: 'Net Long Term Debt Issuance',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                NetCommonStockIssuance: {
                  series: [-15197000000, -13300000000, -13473000000, -12610000000, -12796000000],
                  label: 'Net Common Stock Issuance',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                FinancingCashFlow: {
                  series: [-17817000000, -16214000000, -16511000000, -15254000000, -15991000000],
                  label: 'Financing Cash Flow',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 46,
                    },
                  },
                },
                { name: 'Q2 2022', align: 'center', label: 'Q2 2022', field: 'Q2 2022' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: 'Q1 2022', align: 'center', label: 'Q1 2022', field: 'Q1 2022' },
                { name: 'Q4 2021', align: 'center', label: 'Q4 2021', field: 'Q4 2021' },
                { name: 'Q3 2021', align: 'center', label: 'Q3 2021', field: 'Q3 2021' },
                { name: 'Q2 2021', align: 'center', label: 'Q2 2021', field: 'Q2 2021' },
              ],
              rows: [
                {
                  name: 'Capital Expenditure',
                  'Q2 2022': '-6.83B',
                  'Y/Y change': '-24.24%',
                  'Q1 2022': '-9.79B',
                  'Q4 2021': '-6.38B',
                  'Q3 2021': '-6.82B',
                  'Q2 2021': '-5.5B',
                },
                {
                  name: 'Net PPE Purchase & Sale',
                  'Q2 2022': '-6.83B',
                  'Y/Y change': '-24.24%',
                  'Q1 2022': '-9.79B',
                  'Q4 2021': '-6.38B',
                  'Q3 2021': '-6.82B',
                  'Q2 2021': '-5.5B',
                },
                {
                  name: 'Purchase Of PPE',
                  'Q2 2022': '-6.83B',
                  'Y/Y change': '-24.24%',
                  'Q1 2022': '-9.79B',
                  'Q4 2021': '-6.38B',
                  'Q3 2021': '-6.82B',
                  'Q2 2021': '-5.5B',
                },
                {
                  name: 'Net Business Purchase & Sale',
                  'Q2 2022': '-1.06B',
                  'Y/Y change': '-245.13%',
                  'Q1 2022': '-173M',
                  'Q4 2021': '-385M',
                  'Q3 2021': '-259M',
                  'Q2 2021': '-308M',
                },
                {
                  name: 'Purchase Of Business',
                  'Q2 2022': '-1.06B',
                  'Y/Y change': '-245.13%',
                  'Q1 2022': '-173M',
                  'Q4 2021': '-385M',
                  'Q3 2021': '-259M',
                  'Q2 2021': '-308M',
                },
                {
                  name: 'Net Investment Purchase & Sale',
                  'Q2 2022': '3.48B',
                  'Y/Y change': '205.77%',
                  'Q1 2022': '553M',
                  'Q4 2021': '-4.35B',
                  'Q3 2021': '-3.36B',
                  'Q2 2021': '-3.29B',
                },
                {
                  name: 'Net Other Investing Changes',
                  'Q2 2022': '221M',
                  'Y/Y change': '860.87%',
                  'Q1 2022': '355M',
                  'Q4 2021': '100M',
                  'Q3 2021': '388M',
                  'Q2 2021': '23M',
                },
                {
                  name: 'Purchase Of Investment',
                  'Q2 2022': '-22.23B',
                  'Y/Y change': '10.92%',
                  'Q1 2022': '-29.24B',
                  'Q4 2021': '-40.86B',
                  'Q3 2021': '-35.15B',
                  'Q2 2021': '-24.95B',
                },
                {
                  name: 'Sale Of Investment',
                  'Q2 2022': '25.71B',
                  'Y/Y change': '18.71%',
                  'Q1 2022': '29.79B',
                  'Q4 2021': '36.51B',
                  'Q3 2021': '31.79B',
                  'Q2 2021': '21.66B',
                },
              ],
              title: 'Investing',
              graph: {
                NetInvestmentPurchaseAndSale: {
                  series: [3483000000, 553000000, -4348000000, -3360000000, -3293000000],
                  label: 'Net Investment Purchase And Sale',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                InvestingCashFlow: {
                  series: [-4187000000, -9051000000, -11016000000, -10050000000, -9074000000],
                  label: 'Investing Cash Flow',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                CapitalExpenditure: {
                  series: [-6828000000, -9786000000, -6383000000, -6819000000, -5496000000],
                  label: 'Capital Expenditure',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                NetPPEPurchaseAndSale: {
                  series: [-6828000000, -9786000000, -6383000000, -6819000000, -5496000000],
                  label: 'Net PPE Purchase And Sale',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
              },
            },
            {
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: {
                    _custom: {
                      type: 'function',
                      display: '<span style="opacity:.5;">function</span> field(?)',
                      tooltip: '<pre>row => row.name</pre>',
                      _reviveId: 47,
                    },
                  },
                },
                { name: 'Q2 2022', align: 'center', label: 'Q2 2022', field: 'Q2 2022' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: 'Q1 2022', align: 'center', label: 'Q1 2022', field: 'Q1 2022' },
                { name: 'Q4 2021', align: 'center', label: 'Q4 2021', field: 'Q4 2021' },
                { name: 'Q3 2021', align: 'center', label: 'Q3 2021', field: 'Q3 2021' },
                { name: 'Q2 2021', align: 'center', label: 'Q2 2021', field: 'Q2 2021' },
              ],
              rows: [
                {
                  name: 'Effect Of Exchange Rate Changes',
                  'Q2 2022': '-368M',
                  'Y/Y change': '-301.09%',
                  'Q1 2022': '100M',
                  'Q4 2021': '-181M',
                  'Q3 2021': '-146M',
                  'Q2 2021': '183M',
                },
                {
                  name: 'Beginning Cash Position',
                  'Q2 2022': '20.89B',
                  'Y/Y change': '-21.55%',
                  'Q1 2022': '20.95B',
                  'Q4 2021': '23.72B',
                  'Q3 2021': '23.63B',
                  'Q2 2021': '26.62B',
                },
                {
                  name: 'End Cash Position',
                  'Q2 2022': '17.94B',
                  'Y/Y change': '-24.1%',
                  'Q1 2022': '20.89B',
                  'Q4 2021': '20.95B',
                  'Q3 2021': '23.72B',
                  'Q2 2021': '23.63B',
                },
                {
                  name: 'Changes In Cash',
                  'Q2 2022': '-2.58B',
                  'Y/Y change': '18.68%',
                  'Q1 2022': '-159M',
                  'Q4 2021': '-2.59B',
                  'Q3 2021': '235M',
                  'Q2 2021': '-3.18B',
                },
              ],
              title: 'Net',
              graph: {
                BeginningCashPosition: {
                  series: [20886000000, 20945000000, 23719000000, 23630000000, 26622000000],
                  label: 'Beginning Cash Position',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                EndCashPosition: {
                  series: [17936000000, 20886000000, 20945000000, 23719000000, 23630000000],
                  label: 'End Cash Position',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                ChangesInCash: {
                  series: [-2582000000, -159000000, -2593000000, 235000000, -3175000000],
                  label: 'Changes In Cash',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
              },
            },
          ],
        },
        income: {
          annual: [
            {
              title: 'Statement Info',
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: 'name',
                },
                { name: '2022 (Q2)', align: 'center', label: '2022 (Q2)', field: '2022 (Q2)' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: '2021', align: 'center', label: '2021', field: '2021' },
                { name: '2020', align: 'center', label: '2020', field: '2020' },
                { name: '2019', align: 'center', label: '2019', field: '2019' },
                { name: '2018', align: 'center', label: '2018', field: '2018' },
                { name: '2017', align: 'center', label: '2017', field: '2017' },
              ],
              rows: [
                {
                  2017: '110.86B',
                  2018: '136.82B',
                  2019: '161.86B',
                  2020: '182.53B',
                  2021: '257.64B',
                  name: 'Total Revenue',
                  '2022 (Q2)': '278.14B',
                  'Y/Y change': '7.96%',
                },
                {
                  2017: '110.86B',
                  2018: '136.82B',
                  2019: '161.86B',
                  2020: '182.53B',
                  2021: '257.64B',
                  name: 'Operating Revenue',
                  '2022 (Q2)': '278.14B',
                  'Y/Y change': '7.96%',
                },
                {
                  2017: '45.58B',
                  2018: '59.55B',
                  2019: '71.9B',
                  2020: '84.73B',
                  2021: '110.94B',
                  name: 'Cost Of Revenue',
                  '2022 (Q2)': '120.31B',
                  'Y/Y change': '8.45%',
                },
                {
                  2017: '65.27B',
                  2018: '77.27B',
                  2019: '89.96B',
                  2020: '97.8B',
                  2021: '146.7B',
                  name: 'Gross Profit',
                  '2022 (Q2)': '157.83B',
                  'Y/Y change': '7.59%',
                },
                {
                  2017: '6.84B',
                  2018: '6.92B',
                  2019: '9.55B',
                  2020: '11.05B',
                  2021: '13.51B',
                  name: 'General & Administrative Expense',
                  '2022 (Q2)': '14.43B',
                  'Y/Y change': '6.79%',
                },
                {
                  2017: '16.63B',
                  2018: '21.42B',
                  2019: '26.02B',
                  2020: '27.57B',
                  2021: '31.56B',
                  name: 'Research & Development',
                  '2022 (Q2)': '35.36B',
                  'Y/Y change': '12.04%',
                },
                {
                  2017: '36.36B',
                  2018: '44.68B',
                  2019: '54.03B',
                  2020: '56.57B',
                  2021: '67.98B',
                  name: 'Operating Expense',
                  '2022 (Q2)': '75.36B',
                  'Y/Y change': '10.86%',
                },
                {
                  2017: '81.94B',
                  2018: '104.22B',
                  2019: '125.93B',
                  2020: '141.3B',
                  2021: '178.92B',
                  name: 'Total Expenses',
                  '2022 (Q2)': '195.68B',
                  'Y/Y change': '9.36%',
                },
                {
                  2017: '34.22B',
                  2018: '44.06B',
                  2019: '51.51B',
                  2020: '61.91B',
                  2021: '103.52B',
                  name: 'EBITDA',
                  '2022 (Q2)': '100.2B',
                  'Y/Y change': '-3.21%',
                },
                {
                  2017: '27.3B',
                  2018: '35.03B',
                  2019: '39.73B',
                  2020: '48.22B',
                  2021: '91.08B',
                  name: 'EBIT',
                  '2022 (Q2)': '85.77B',
                  'Y/Y change': '-5.83%',
                },
                {
                  2017: '109M',
                  2018: '114M',
                  2019: '100M',
                  2020: '135M',
                  2021: '346M',
                  name: 'Interest Expense',
                  '2022 (Q2)': '360M',
                  'Y/Y change': '4.05%',
                },
                {
                  2017: '1.31B',
                  2018: '1.88B',
                  2019: '2.43B',
                  2020: '1.87B',
                  2021: '1.5B',
                  name: 'Interest Income',
                  '2022 (Q2)': '1.67B',
                  'Y/Y change': '11.07%',
                },
                {
                  2017: '27.19B',
                  2018: '34.91B',
                  2019: '39.63B',
                  2020: '48.08B',
                  2021: '90.73B',
                  name: 'Pretax Income',
                  '2022 (Q2)': '85.41B',
                  'Y/Y change': '-5.86%',
                },
                {
                  2017: '12.66B',
                  2018: '30.74B',
                  2019: '34.34B',
                  2020: '40.27B',
                  2021: '76.03B',
                  name: 'Net Income Continuous Operations',
                  '2022 (Q2)': '72.02B',
                  'Y/Y change': '-5.28%',
                },
                {
                  2017: '12.66B',
                  2018: '30.74B',
                  2019: '34.34B',
                  2020: '40.27B',
                  2021: '76.03B',
                  name: 'Net Income',
                  '2022 (Q2)': '72.02B',
                  'Y/Y change': '-5.28%',
                },
                {
                  2017: '18.27',
                  2018: '44.22',
                  2019: '49.59',
                  2020: '2.96',
                  2021: '5.69',
                  name: 'Basic EPS',
                  '2022 (Q2)': '5.44',
                  'Y/Y change': '-4.38%',
                },
                {
                  2017: '12.66B',
                  2018: '30.74B',
                  2019: '34.34B',
                  2020: '40.27B',
                  2021: '76.03B',
                  name: 'Net Income Common Stockholders',
                  '2022 (Q2)': '72.02B',
                  'Y/Y change': '-5.28%',
                },
              ],
              graph: {
                TotalRevenue: {
                  series: [278139000000, 257637000000, 182527000000, 161857000000, 136819000000],
                  label: 'Total Revenue',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                GrossProfit: {
                  series: [157827000000, 146698000000, 97795000000, 89961000000, 77270000000],
                  label: 'Gross Profit',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                EBITDA: {
                  series: [100198000000, 103521000000, 61914000000, 51506000000, 44062000000],
                  label: 'EBITDA',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                EBIT: {
                  series: [85774000000, 91080000000, 48217000000, 39725000000, 35027000000],
                  label: 'EBIT',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
                NetIncome: {
                  series: [72016000000, 76033000000, 40269000000, 34343000000, 30736000000],
                  label: 'Net Income',
                  periodLabels: ['2022-06-30', '2021-12-31', '2020-12-31', '2019-12-31', '2018-12-31'],
                },
              },
            },
          ],
          quarterly: [
            {
              title: 'Statement Info',
              cols: [
                {
                  name: 'name',
                  required: true,
                  label: '',
                  align: 'left',
                  style: 'max-width: 120px',
                  headerStyle: 'max-width: 120px',
                  field: 'name',
                },
                { name: 'Q2 2022', align: 'center', label: 'Q2 2022', field: 'Q2 2022' },
                { name: 'Y/Y change', align: 'center', label: 'Y/Y change', field: 'Y/Y change' },
                { name: 'Q1 2022', align: 'center', label: 'Q1 2022', field: 'Q1 2022' },
                { name: 'Q4 2021', align: 'center', label: 'Q4 2021', field: 'Q4 2021' },
                { name: 'Q3 2021', align: 'center', label: 'Q3 2021', field: 'Q3 2021' },
                { name: 'Q2 2021', align: 'center', label: 'Q2 2021', field: 'Q2 2021' },
              ],
              rows: [
                {
                  name: 'Total Revenue',
                  'Q2 2022': '69.69B',
                  'Y/Y change': '12.61%',
                  'Q1 2022': '68.01B',
                  'Q4 2021': '75.33B',
                  'Q3 2021': '65.12B',
                  'Q2 2021': '61.88B',
                },
                {
                  name: 'Operating Revenue',
                  'Q2 2022': '69.69B',
                  'Y/Y change': '12.61%',
                  'Q1 2022': '68.01B',
                  'Q4 2021': '75.33B',
                  'Q3 2021': '65.12B',
                  'Q2 2021': '61.88B',
                },
                {
                  name: 'Cost Of Revenue',
                  'Q2 2022': '30.1B',
                  'Y/Y change': '14.78%',
                  'Q1 2022': '29.6B',
                  'Q4 2021': '32.99B',
                  'Q3 2021': '27.62B',
                  'Q2 2021': '26.23B',
                },
                {
                  name: 'Gross Profit',
                  'Q2 2022': '39.58B',
                  'Y/Y change': '11.02%',
                  'Q1 2022': '38.41B',
                  'Q4 2021': '42.34B',
                  'Q3 2021': '37.5B',
                  'Q2 2021': '35.65B',
                },
                {
                  name: 'General & Administrative Expense',
                  'Q2 2022': '3.66B',
                  'Y/Y change': '9.46%',
                  'Q1 2022': '3.37B',
                  'Q4 2021': '4.14B',
                  'Q3 2021': '3.26B',
                  'Q2 2021': '3.34B',
                },
                {
                  name: 'Research & Development',
                  'Q2 2022': '9.84B',
                  'Y/Y change': '28.22%',
                  'Q1 2022': '9.12B',
                  'Q4 2021': '8.71B',
                  'Q3 2021': '7.69B',
                  'Q2 2021': '7.68B',
                },
                {
                  name: 'Operating Expense',
                  'Q2 2022': '20.13B',
                  'Y/Y change': '23.55%',
                  'Q1 2022': '18.32B',
                  'Q4 2021': '20.45B',
                  'Q3 2021': '16.47B',
                  'Q2 2021': '16.29B',
                },
                {
                  name: 'Total Expenses',
                  'Q2 2022': '50.23B',
                  'Y/Y change': '18.14%',
                  'Q1 2022': '47.92B',
                  'Q4 2021': '53.44B',
                  'Q3 2021': '44.09B',
                  'Q2 2021': '42.52B',
                },
                {
                  name: 'EBITDA',
                  'Q2 2022': '23B',
                  'Y/Y change': '-8.04%',
                  'Q1 2022': '22.8B',
                  'Q4 2021': '27.96B',
                  'Q3 2021': '26.45B',
                  'Q2 2021': '25.01B',
                },
                {
                  name: 'EBIT',
                  'Q2 2022': '19.1B',
                  'Y/Y change': '-13.44%',
                  'Q1 2022': '19.02B',
                  'Q4 2021': '24.52B',
                  'Q3 2021': '23.14B',
                  'Q2 2021': '22.06B',
                },
                {
                  name: 'Interest Expense',
                  'Q2 2022': '83M',
                  'Y/Y change': '9.21%',
                  'Q1 2022': '83M',
                  'Q4 2021': '117M',
                  'Q3 2021': '77M',
                  'Q2 2021': '76M',
                },
                {
                  name: 'Interest Income',
                  'Q2 2022': '486M',
                  'Y/Y change': '24.94%',
                  'Q1 2022': '414M',
                  'Q4 2021': '378M',
                  'Q3 2021': '387M',
                  'Q2 2021': '389M',
                },
                {
                  name: 'Pretax Income',
                  'Q2 2022': '19.01B',
                  'Y/Y change': '-13.51%',
                  'Q1 2022': '18.93B',
                  'Q4 2021': '24.4B',
                  'Q3 2021': '23.06B',
                  'Q2 2021': '21.99B',
                },
                {
                  name: 'Net Income Continuous Operations',
                  'Q2 2022': '16B',
                  'Y/Y change': '-13.62%',
                  'Q1 2022': '16.44B',
                  'Q4 2021': '20.64B',
                  'Q3 2021': '18.94B',
                  'Q2 2021': '18.53B',
                },
                {
                  name: 'Net Income',
                  'Q2 2022': '16B',
                  'Y/Y change': '-13.62%',
                  'Q1 2022': '16.44B',
                  'Q4 2021': '20.64B',
                  'Q3 2021': '18.94B',
                  'Q2 2021': '18.53B',
                },
                {
                  name: 'Basic EPS',
                  'Q2 2022': '1.22',
                  'Y/Y change': '-11.59%',
                  'Q1 2022': '1.25',
                  'Q4 2021': '1.56',
                  'Q3 2021': '1.42',
                  'Q2 2021': '1.38',
                },
                {
                  name: 'Net Income Common Stockholders',
                  'Q2 2022': '16B',
                  'Y/Y change': '-13.2%',
                  'Q1 2022': '16.44B',
                  'Q4 2021': '20.64B',
                  'Q3 2021': '18.94B',
                  'Q2 2021': '18.44B',
                },
              ],
              graph: {
                TotalRevenue: {
                  series: [69685000000, 68011000000, 75325000000, 65118000000, 61880000000],
                  label: 'Total Revenue',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                GrossProfit: {
                  series: [39581000000, 38412000000, 42337000000, 37497000000, 35653000000],
                  label: 'Gross Profit',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                EBITDA: {
                  series: [22996000000, 22799000000, 27958000000, 26445000000, 25006000000],
                  label: 'EBITDA',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                EBIT: {
                  series: [19097000000, 19017000000, 24519000000, 23141000000, 22061000000],
                  label: 'EBIT',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
                NetIncome: {
                  series: [16002000000, 16436000000, 20642000000, 18936000000, 18525000000],
                  label: 'Net Income',
                  periodLabels: ['2022-06-30', '2022-03-31', '2021-12-31', '2021-09-30', '2021-06-30'],
                },
              },
            },
          ],
        },
      },
      technicalAnalysis: [
        { time: 'One Week', timeLeft: '2 days left', trend: 'Bearish Trend' },
        { time: 'Two Week', timeLeft: '2 days left', trend: 'Neutral' },
        { time: 'One Month', timeLeft: '2 days left', trend: 'Bullish Trend' },
        { time: 'Two Month', timeLeft: '2 days left', trend: 'Bullish Trend' },
      ],
      optionsInfo: {
        impliedVolatility: [
          { title: 'IV Percentile', weeks: [0.5238, 0.56, 0.6417, 0.7331] },
          { title: 'IV Ranking', weeks: [0.5039, 0.4488, 0.5218, 0.5607] },
          { title: 'IV Low', weeks: [0.2608, 0.2498, 0.2134, 0.1891] },
          { title: 'IV High', weeks: [0.4513, 0.4488, 0.4882, 0.4882] },
        ],
        impliedVolatilityPreds: [
          { time: 'IV 1 Year High', amount: 0.4882 },
          { time: 'IV 1 Year Low', amount: 0.1891 },
          { time: 'IV', amount: 0.3579 },
          { time: 'IV change', amount: 0.0092 },
        ],
        volumes: [
          { title: 'Put/Call Interest', amount: 1.4201 },
          { title: 'Options Volume', amount: 369305 },
          { title: 'Options Interest', amount: 3796847 },
        ],
      },
    },
    currentTickerStrategy: {},
    language: 'en-US',
    marketScanData: []
    //
  };
}
