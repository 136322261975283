export function getTicker(state) {
  return state.currentTickerInfo.ticker;
}

export function getOptionsInfo(state) {
  return state.currentTickerInfo.optionsInfo;
}

export function getFundamentalData(state) {
  return state.currentTickerInfo.fundamentalData;
}

export function currentTickerStrategy(state) {
  return state.currentTickerStrategy;
}
export function getForecast(state) {
  return state.currentTickerInfo.forecast;
}

export function getStrategyDetailInfo(state) {
  return state.strategyDetailInfo;
}

export function getStrategyPerformance(state) {
  return state.currentTickerStrategy.performance;
}

export function getStrategyQuoteDetails(state) {
  return state.currentTickerStrategy.quoteDetails;
}

export function getStrategyGreeks(state) {
  return state.currentTickerStrategy.greeks;
}

export function getStrategyLegs(state) {
  return state.currentTickerStrategy.legs;
}

export function getStrategyScenarios(state) {
  return state.currentTickerStrategy.scenarios;
}

export function getStrategyInfo(state) {
  return state.currentTickerStrategy.strategyInfo;
}

export function getStrategyTicker(state) {
  return state.currentTickerStrategy.ticker;
}

export function getStrategyOptionsDataAnalytics(state) {
  return state.currentTickerStrategy.optionsDataAnalytics;
}

export function getStrategyHistoricVolatility(state) {
  return state.currentTickerStrategy.historicVolatility;
}

export function getStrategyCompanyFundamentals(state) {
  return state.currentTickerStrategy.companyFundamentals;
}

export function getStrategyDates(state) {
  return state.currentTickerStrategy.allDates;
}
export function getStrategySeries(state) {
  return state.currentTickerStrategy.series;
}

export function getEnhancePriceData(state) {
  return state.currentTickerStrategy.enhancePriceData;
}
export function getLastUpdate(state) {
  return state.currentTickerInfo.lastUpdate;
}
export function getLanguage(state) {
  return state.language;
}

export function getMarketscanData(state) {
  return state.marketScanData;
}
