<template>
  <q-img src="/loader.gif" style="height: 300px; width: 300px" ></q-img>
</template>

<script>
export default {
  name: "BrandSpinner.vue"
}
</script>

<style scoped>

</style>
