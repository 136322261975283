import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'

import demoProducts from './demoProducts'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      demoProducts
      // example
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })

  return Store
})

// import { store } from "quasar/wrappers";
// import Vuex from "vuex";

// import demoProducts from './demoProducts'
// // import userData from "./userData";
// // import common from "./common";
// // import marketScan from "./marketScan";
// // import tickerScan from "./tickerScan";
// // import fundamentalExplorer from "./fundamentalExplorer";
// // import pop from "./pop";

// // import example from './module-example';
// // import { ExampleStateInterface } from './module-example/state';
// /*
//  * If not building with SSR mode, you can
//  * directly export the Store instantiation
//  */

// export default store(function({ Vue }) {
//   // Vue.use(Vuex);

//   const Store = new Vuex.Store({
//     modules: {
//       demoProducts
//       // userData,
//       // common,
//       // marketScan,
//       // tickerScan,
//       // fundamentalExplorer,
//       // pop
//     },

//     // enable strict mode (adds overhead!)
//     // for dev mode only
//     strict: !!process.env.DEBUGGING
//   });

//   return Store;
// });

