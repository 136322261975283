import {  Platform } from 'quasar'

const routes = [
  {
    path: '/',
    component: () => import('layouts/B2BLayout.vue'),
    // children: [{ path: '', component: () => Platform.is.mobile ? import('pages/Index.vue') : import('pages/B2BPage.vue') }],
    children: [{ path: '', component: () => import('pages/B2BPage.vue') }],
  },
  {
    path: '/B2CServices',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/Index.vue') }],
  },
  {
    path: '/Education',
    component: () => import('src/layouts/EducationLayout.vue'),
    children: [{ path: '', component: () => import('pages/Education.vue') }],
  },
  {
    path: '/marketNews',
    component: () => import('src/layouts/MarketNewsLayout.vue'),
    children: [{ path: '', component: () => import('pages/MarketNews.vue') }],
  },
  {
    path: '/Unsubscribe',
    component: () => import('src/layouts/UnsubscribeLayout.vue'),
    children: [{ path: '', component: () => import('pages/Unsubscribe.vue') }],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
