<template>
  <router-view />
</template>
<script>
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import BrandSpinner from './components/Common/Dialogs/BrandSpinner.vue';

export default defineComponent({
  name: 'App',
  data() {
    return {
      language: 'en-ES',
    };
  },
  beforeCreate() {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('lang');
    if (language === 'en-ES' || language === 'en-US') {
      this.$i18n.locale = language;
      this.language = language;
    }
  },
  created() {
    this.$q.dark.set(true);
    this.$q.loading.setDefaults({ spinner: BrandSpinner });
    this.setLanguage(this.language);
  },
  methods: {
    ...mapMutations({
      setLanguage: 'demoProducts/setLanguage',
    }),
  },
});
</script>

<style lang="scss">
// ::-webkit-scrollbar {
//   height: 10px;
//   width: 10px;
//   z-index: 12;
//   overflow: auto;
//   background: transparent;
// }

::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  //border-radius: 10px;
  z-index: 12;
  //border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  transition: background-color 0.32s ease-in-out;
  margin: 4px;
  min-height: 32px;
  min-width: 32px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}
</style>
